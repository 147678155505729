<template>
  <div class="other-profile">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="other-profile__container container">
      <div class="other-profile__container__left left-side">
        <OrganizationProfile :accountId="state.accountId"/>
      </div>
      <div class="other-profile__container__right right-main-area">
        <div class="other-profile__container__right__tab">
          <router-link :to="{ path: '/organization/' + state.organizationId + '/info' }">{{state.aLang.other_organization_info}}</router-link>
          <router-link :to="{ path: '/organization/' + state.organizationId + '/recruiting' }">{{state.aLang.other_organization_recruiting_projects}}</router-link>
        </div>
        <router-view :preload="state.preload" :breadcrumb="state.listBreadcrumb" :organizationInfo="state.organizationInfo" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/other-profile/OtherProfile.ts" />
<style lang="scss" src="@/presentation/views/organization/other-profile/OtherProfile.scss" />
