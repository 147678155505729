import { reactive, defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import NoPhoto from "@/assets/images/common/no_photo.png";
import OrganizationProfile from "@/presentation/components/organization-profile/OrganizationProfile.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import LanguageUtil from "@/commons/LanguageUtil";
import routerPath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
export default defineComponent({
    name: "Engineer",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        OrganizationProfile: OrganizationProfile
    },
    setup: function () {
        var store = useStore();
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var router = useRouter();
        var route = useRoute();
        var preload = ref(true);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            uploadfile: {
                image: NoPhoto,
                name: null,
            },
            preload: true,
            listBreadcrumb: [
                { name: "ダッシュボード", route: "/organization/dashboard/projects" },
                { name: "", route: "" }
            ],
            organizationInfo: {},
            isShowSMSPopup: false,
            organizationId: "",
            accountId: null
        });
        onMounted(function () {
            var accountId = route.params.organizationId;
            state.organizationId = accountId !== null && accountId !== void 0 ? accountId : "";
            var role = CommonService.getCookie("user_roles");
            state.listBreadcrumb[0].route = role == "user" ? "/user/dashboard/projects" : "/organization/dashboard/projects";
            state.listBreadcrumb[1].route = "/organization/" + accountId + "/info";
            organizationInteractor
                .getOrganProfile(accountId)
                .then(function (result) {
                if (!result.data) {
                    state.preload = false;
                    store.commit("setOrganizationProfileInfo", {});
                    return;
                }
                state.preload = false;
                state.organizationInfo = result.data;
                state.listBreadcrumb[1].name = result.data.name;
                store.commit("setOrganizationProfileInfo", result.data);
            })
                .catch(function (error) {
                state.preload = false;
                router.push(routerPath.PAGE_404);
            });
            state.accountId = accountId;
        });
        return {
            state: state
        };
    }
});
